import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'

import AListTextItem from '../components/01-atoms/AListTextItem'
import AType from '../components/01-atoms/AType'

import MListText from '../components/02-molecules/MListText'

import OIntro from '../components/03-organisms/OIntro'

const IndexPage = ({ data }) => (
  <Layout>
    <Helmet>
      <title>Leistungen</title>
      <meta
        name="description"
        content="Unsere Leistungen beim Augenoptiker »Optique - Meisterhandwerk und Brillenmanufaktur«: Sehtest, Sehberatung, Beratung, Kontaktlinse, Brille, Fassungsberatung, Kundenservice"
      />
    </Helmet>

    <OIntro
      title={
        <AType type="h1" className="title">
          Unsere Leistungen
        </AType>
      }
      subtitle={
        <AType type="h2" className="subtitle">
          Optique – Meisterhandwerk und Brillenmanufaktur
          <br /> Augenoptiker in Stuttgart-Süd
        </AType>
      }
    />

    <MListText
      intro={
        <div>
          <Img
            fluid={data.co.childImageSharp.fluid}
            className="content-img"
            backgroundColor="#29332c"
            alt="Optikerin Corinna Becht in der Optique Stuttgart"
          />
          <AType className="lead" align="center">
            Unsere Augen sind heutzutage extremen Sehanforderungen ausgesetzt,
            sei es beim langen Arbeiten am PC, weiten Distanzen in der Vorlesung
            oder langen Autofahrten in diffusen Lichtverhältnissen – für unsere
            Augen Dauerstress. Nicht selten haben wir mit Kopfschmerzen,
            Müdigkeit, Unkonzentriertheit zu kämpfen. Dass dies an
            unkorrigierter Sehschwäche liegen kann, kommt einem oft gar nicht in
            den Sinn. Eine Brille mit den richtigen Gläsern hilft die Augen zu
            entlasten.
          </AType>
        </div>
      }
    >
      <AListTextItem
        title="Sehtest"
        paragraph={
          <AType>
            Sehtest kostenlos, schnell und unverbindlich an modernsten Geräten.
            Liefert aussagekräftige Ergebnisse um urteilen zu können ob eine
            (neue) Korrektur erforderlich ist.
          </AType>
        }
      />
      <AListTextItem
        title="Sehberatung"
        paragraph={
          <AType>
            Auffälligkeiten beim Sehtest – wir entscheiden zusammen, ob eine
            ausführlichere Sehberatung infrage kommt. Unter Sehberatung
            verstehen wir eine ausführliche Überprüfung der Sehstärke, bezogen
            auf die ganz speziellen Einsatzgebiete der Brillen.
            <br />
            Unter Nutzung neuester Technologien wird mit einem Screening
            begonnen um eventuelle Auffälligkeiten festzustellen. Anschließend
            nutzen wir ein 3D-gestütztes Messverfahren um genauste Ergebnisse
            bei der Vermessung Ihres beidäugigen Sehens zu erzielen und auch
            besondere Auffälligkeiten wie beispielsweise Phorien zu erkennen.
          </AType>
        }
      />
      <AListTextItem
        title="Beratung: Kontaktlinsen oder Brille?"
        paragraph={
          <AType>
            Je nach Einsatzgebiet kann die optimale Korrektur Brille,
            Kontaktlinse oder auch die Kombination aus Beidem bedeuten. Bei
            beiden Produkten greifen wir auf erprobte Markenqualität der
            marktführenden Unternehmen (Hoya, Johnson&Johnson, Alcon, uvm.)
            zurück und bieten die komplette Bandbreite, um auch individuellen
            Wünschen wie besonderem UV-Schutz, Tönungen oder besonders
            ästhetischen Materialien zu entsprechen. Der Fortschritt macht im
            Optikerhandwerk nicht Halt – wir informieren über spezielle Gläser
            zum Autofahren, Blaulichtfilter um bestimmte Strahlung abzuhalten
            oder Wellnessgläser für Alle, denen das lange Starren in nahe
            Entfernungen zu anstrengend wird.
          </AType>
        }
      />
      <AListTextItem
        title="Fassungsberatung"
        paragraph={
          <AType>
            Brille als Schmuck, Erleichterung im Alltag, in der Optique wissen
            wir wie wichtig die Auswahl der richtigen Fassung ist und finden das
            optimale Meisterstück. Besuche auf den Fachmessen der Branche,
            stetiger Kollektionswechsel, Kontakte zu Designern – für uns keine
            Pflicht, sondern ein Riesenspaß an Mode und den neusten Trends.{' '}
            <br />
            Im Beratungsgespräch nehmen wir uns die Zeit Ihren Stil mit der
            richtigen Brille zu betonen. Schlussendlich fließen Glasstärke und
            Einsatzgebiete der Brille selbstverständlich mit ein und ergeben ein
            harmonisches Gesamtergebnis.
          </AType>
        }
      />
      <AListTextItem
        title="Kundenservice"
        paragraph={
          <AType>
            Ob Kontaktlinsen-Abo, kostenloser Brillen-TÜV, persönliche
            Erreichbarkeit, Beratung auch außerhalb der regulären
            Öffnungszeiten, mit Partner oder Freunden für Unterstützung bei der
            Brillenauswahl oder auch Hausbesuche – wir möchten einen besonderen
            Service anbieten um dem Thema Sehen die Aufmerksamkeit zukommen zu
            lassen die es verdient.
            <br />
            Service der Spaß macht, Service der unkompliziert ist und Service,
            auf den man sich verlassen kann.
          </AType>
        }
      />
    </MListText>
  </Layout>
)
IndexPage.propTypes = {
  data: PropTypes.shape.isRequired,
}
export default IndexPage

export const query = graphql`
  query {
    co: file(
      relativePath: { eq: "shop/augenoptiker_optique__MG_2799_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900, excludeOriginal: true) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
